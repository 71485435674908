import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { PartnerUser } from '../models/api/PartnerUser';
import { UserRequest } from '../models/api/UserRequest';
import { BcUser } from '../models/auth/BcUser';
import { ApiService } from './api.service';
import { Role } from '@app/@shared/constants/role.constant';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserRolesService {
  public partnerUsers: PartnerUser[] = [];
  public changedPartnerUsers: PartnerUser[] = [];
  public partnerNo: string = '';

  constructor(private api: ApiService, private messageService: MessageService) {
    this.selectedUserRequest = new BehaviorSubject<UserRequest>(new UserRequest());
  }

  private user: BcUser = new BcUser();

  public SetUser(user: BcUser) {
    this.user = user;
    if (this.user.roles == null) {
      this.user.roles = ['empty'];
    }
  }

  public setUpPartnerUsers(partnerNo: string): void {
    this.partnerNo = partnerNo;
    this.api.partnerUsers.subscribe((partnerUsers) => {
      this.SetPartnerUsers(partnerUsers);
    });
    this.api.getPartnerUsers(this.partnerNo);
  }

  public SetPartnerUsers(partnerUsers: PartnerUser[]): void {
    if (partnerUsers == null) return;
    this.partnerUsers = partnerUsers;

    for (var i = 0; i < this.partnerUsers.length; i++) {
      this.partnerUsers[i].portal = this.partnerUsers[i].roles.includes(Role.PORTAL);
      this.partnerUsers[i].jira = this.partnerUsers[i].roles.includes(Role.BCSUPPORT);
      this.partnerUsers[i].admin = this.partnerUsers[i].roles.includes(Role.BCADMIN);
      this.partnerUsers[i].accounts = this.partnerUsers[i].roles.includes(Role.BCACCOUNT);
      this.partnerUsers[i].customers = this.partnerUsers[i].roles.includes(Role.BCCUSTOME);
      this.partnerUsers[i].invoices = this.partnerUsers[i].roles.includes(Role.BCINVOICE);
      this.partnerUsers[i].orders = this.partnerUsers[i].roles.includes(Role.BCORDERS);
      this.partnerUsers[i].viewer = this.partnerUsers[i].roles.includes(Role.BCVIEWER);

      this.partnerUsers[i].contract = this.partnerUsers[i].roles.includes(Role.AGREECONT);
      this.partnerUsers[i].agreeMarketing = this.partnerUsers[i].roles.includes(Role.AGREEMARK);
      this.partnerUsers[i].softLicense = this.partnerUsers[i].roles.includes(Role.AGREESOFT);
      this.partnerUsers[i].technical = this.partnerUsers[i].roles.includes(Role.AGREETECH);
      this.partnerUsers[i].epNotifications = this.partnerUsers[i].roles.includes(Role.BREP);
      this.partnerUsers[i].training = this.partnerUsers[i].roles.includes(Role.LSACADEMY);
      this.partnerUsers[i].financial = this.partnerUsers[i].roles.includes(Role.FINANCIAL);

      this.partnerUsers[i].emailInv = this.partnerUsers[i].roles.includes(Role.EMAILINV);
      this.partnerUsers[i].sales = this.partnerUsers[i].roles.includes(Role.SALES);

      if (this.partnerUsers[i].admin) {
        this.adminChecked(i);
      }
    }
  }

  UserExists(email: string): Boolean {
    return this.partnerUsers.some((user) => user.email.toLowerCase() == email.toLowerCase());
  }

  setAddedRole(userId: number, role: string) {
    if (this.partnerUsers[userId].removedRoles.includes(role)) {
      this.partnerUsers[userId].removedRoles = this.partnerUsers[userId].removedRoles.filter((r) => r !== role);
    }
    if (!this.partnerUsers[userId].roles.includes(role)) {
      this.partnerUsers[userId].addedRoles.push(role);
    }
  }

  setRemovedRole(userId: number, role: string) {
    if (this.partnerUsers[userId].addedRoles.includes(role)) {
      this.partnerUsers[userId].addedRoles = this.partnerUsers[userId].addedRoles.filter((r) => r !== role);
    }
    if (this.partnerUsers[userId].roles.includes(role)) {
      this.partnerUsers[userId].removedRoles.push(role);
    }
  }

  adminChecked(userId: number) {
    if (this.partnerUsers[userId].accounts) {
      this.setRemovedRole(userId, Role.BCACCOUNT);
    }

    if (this.partnerUsers[userId].customers) {
      this.setRemovedRole(userId, Role.BCCUSTOME);
    }

    if (this.partnerUsers[userId].invoices) {
      this.setRemovedRole(userId, Role.BCINVOICE);
    }
  }

  rollbackRemovedRole(userId: number, role: string) {
    this.partnerUsers[userId].removedRoles = this.partnerUsers[userId].removedRoles.filter((r) => r !== role);
  }

  adminUnchecked(userId: number) {
    var pagesRoles = [Role.BCACCOUNT, Role.BCCUSTOME, Role.BCINVOICE];

    pagesRoles.forEach((role) => {
      if (this.partnerUsers[userId].removedRoles.includes(role) && this.partnerUsers[userId].roles.includes(role)) {
        this.rollbackRemovedRole(userId, role);
      }
    });
  }

  roleChange(checked: boolean, user: PartnerUser, role: string): void {
    var userId = this.partnerUsers.findIndex((pu) => pu.email === user.email);

    this.partnerUsers[userId].changed = true;
    if (checked) {
      this.setAddedRole(userId, role);

      if (role === Role.BCADMIN) {
        this.adminChecked(userId);
      }
    } else {
      this.setRemovedRole(userId, role);

      if (role === Role.BCADMIN) {
        this.adminUnchecked(userId);
      }
    }
    //console.log(this.partnerUsers.filter(pu => pu.changed && (pu.addedRoles.length != 0 || pu.removedRoles.length != 0)));
  }

  public verifyPartnerUsersChanges(): boolean {
    this.changedPartnerUsers = this.partnerUsers.filter(
      (pu) => pu.addedRoles.length != 0 || pu.removedRoles.length != 0
    );

    return this.changedPartnerUsers.length != 0;
  }

  selectedUserRequest: BehaviorSubject<UserRequest>;
  pendingUserRequestSelected(request: UserRequest) {
    this.selectedUserRequest.next(request);
  }

  savePartnerUsers(): Observable<boolean> {
    return this.api.savePartnerUsers(this.changedPartnerUsers).pipe(
      tap((result) => {
        if (result) {
          this.api.getPartnerUsers(this.partnerNo);

          this.messageService.add({
            severity: 'success',
            summary: 'User roles saved.',
            detail: 'Users has been successfully updated.',
            sticky: true,
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There is an internal service problem. Please contact administrator.',
            sticky: true,
          });
        }
      })
    );
  }
}
